
import Utils from "./index";

export default class UtilsUser {

    static async addTicketQueueFavorite(uuid: string) {
        let url = `/api/v1/actions/user_ticket_queue_add`
        const r = await Utils.entity.request(url, "POST", {
            uuid
        })
        return r;
    }

    static async removeTicketQueueFavorite(uuid: string) {
        let url = `/api/v1/actions/user_ticket_queue_remove`
        const r = await Utils.entity.request(url, "POST", {
            uuid
        })
        return r;
    }
}